/** @jsxRuntime classic */
/** @jsx jsx */

import { forwardRef } from 'react';

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';
import * as colors from '<components>/colors';
import * as resets from '<components>/resets';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';

const actionBG = {
  add: colors.blue1,
  cancel: colors.red1c,
  control: 'transparent',
  create: colors.blue1,
  delete: colors.red1c,
  deploy: colors.green2,
  run: colors.green2,
};
const actionHov = {
  add: colors.blue2,
  cancel: colors.red1b,
  create: colors.blue2,
  delete: colors.red1b,
  deploy: colors.green1,
  run: colors.green1,
};
const actionFG = {
  add: 'white',
  cancel: 'white',
  control: colors.gray2,
  create: 'white',
  delete: 'white',
  deploy: 'white',
  run: 'white',
};

function StyledButton(
  {
    styleProps: {
      action,
      isLarge,
      isSmall,
      isNormal,
      isRound,
      hasIcon,
      noIcon,
      onlyIcon,
      hasContent,
      noContent,
      onlyContent,
      iconOnRight,
      iconOnLeft,
      textTransform,
      disabled,
      disabledStyles,
      justifyContent,
    },
    ...rest
  },
  parentRef
) {
  return (
    <button
      {...rest}
      ref={parentRef}
      css={css({
        ...resets.button,
        boxSizing: 'border-box',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: justifyContent || 'center',
        fontSize: isSmall ? '0.85em' : isLarge ? '1.1em' : '1em',
        width: 'fit-content',
        color: actionFG[action] ? actionFG[action] : colors.gray2,
        background: actionBG[action] ? actionBG[action] : 'transparent',
        outline: 'none',
        borderWidth: onlyIcon ? 0 : 1,
        borderStyle: 'solid',
        borderColor: action ? 'transparent' : colors.gray2,
        borderRadius: isRound ? '50px' : '4px',
        whiteSpace: 'nowrap',

        padding: onlyIcon
          ? '4px 5px 5px 5px'
          : isSmall
            ? '4px 8px'
            : '6px 12px',

        cursor: 'pointer',

        textTransform: textTransform,
        letterSpacing: isSmall ? 0.5 : 1,
        fontWeight: 500,
        transition: 'all 125ms ease-in',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        i: {
          fontSize: isSmall ? '11px' : isLarge ? '16px' : '13px',
          margin: onlyIcon
            ? '0'
            : iconOnRight
              ? '0 0 0 3px'
              : iconOnLeft
                ? '0 5px 0 0'
                : '0',
          lineHeight: 'normal !important',
          paddingLeft: '1px',
          ...(isLarge && { paddingBottom: '1x' }),
        },
        svg: {
          fontSize: isSmall ? '11px' : isLarge ? '14px' : '13px',
          margin: onlyIcon
            ? '0'
            : iconOnRight
              ? '0 0 0 5px'
              : iconOnLeft
                ? '0 5px 0 0'
                : '0',
        },

        '&:hover': {
          outline: 'none',
          background: actionHov[action] ? actionHov[action] : colors.yellow4,
        },

        '&:focus': {
          boxShadow: `none`,
        },

        '&:active': {
          boxShadow: 'none',
        },

        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          color: colors.gray1,
          backgroundColor: onlyIcon ? 'transparent' : colors.gray3,
          borderColor: colors.gray3,
          ...(disabledStyles && { ...disabledStyles, opacity: 1 }),
        },
      })}
    />
  );
}
// eslint-disable-next-line no-func-assign
StyledButton = forwardRef(StyledButton);
StyledButton.displayName = 'StyledButton';

/*
Design Spec
- size: small/normal/large
- icon:  jsx
- iconPosition: left/right
- action: run/deploy/cancel/rerun/save/add/control
- content: text or children components
- textTransform: lowercase/uppercase
- justifyContent: flex justifyContent
- disabledStyles (might not need this one - figure out based on action prop)
*/

function ButtonLogic(props, parentRef) {
  const {
    size = 'normal',
    icon,
    iconPosition = 'right',
    action,
    children: content,
    textTransform,
    justifyContent,
    round,
    disabled,
    disabledStyles,
    ...rest
  } = props;

  const styleProps = {
    action,
    isLarge: size === 'large',
    isSmall: size === 'small',
    isNormal: !size || size === 'normal',
    isRound: !!round,
    hasIcon: !!icon,
    noIcon: !icon,
    onlyIcon: !!icon && !content,
    hasContent: !!content,
    noContent: !content,
    onlyContent: !!content && !icon,
    iconOnRight: !!icon && iconPosition === 'right',
    iconOnLeft: !!icon && iconPosition === 'left',
    textTransform: textTransform ? textTransform : 'none',
    disabled,
    disabledStyles,
    justifyContent,
  };

  // const Component =
  //   tag === 'button' ? StyledButton : StyledButton.withComponent(tag);

  const Component = StyledButton;

  if (styleProps.onlyIcon)
    return (
      <Component
        {...rest}
        ref={parentRef}
        styleProps={styleProps}
        disabled={disabled}
      >
        {icon}
      </Component>
    );

  if (styleProps.iconOnLeft)
    return (
      <Component
        {...rest}
        ref={parentRef}
        styleProps={styleProps}
        disabled={disabled}
      >
        {icon}
        {content}
      </Component>
    );

  if (styleProps.iconOnRight)
    return (
      <Component
        {...rest}
        ref={parentRef}
        styleProps={styleProps}
        disabled={disabled}
      >
        {content}
        {icon}
      </Component>
    );

  if (styleProps.onlyContent)
    return (
      <Component
        {...rest}
        ref={parentRef}
        styleProps={styleProps}
        disabled={disabled}
      >
        {content}
      </Component>
    );

  if (styleProps.noIcon && styleProps.noContent)
    return (
      <Component
        {...rest}
        ref={parentRef}
        styleProps={styleProps}
        disabled={disabled}
      />
    );

  return (
    <Component
      {...rest}
      ref={parentRef}
      styleProps={styleProps}
      disabled={disabled}
    >
      {icon}
      <span>{content}</span>
    </Component>
  );
}
// eslint-disable-next-line no-func-assign
ButtonLogic = forwardRef(ButtonLogic);
ButtonLogic.displayName = 'ButtonLogic';

function makeVariant(displayName, extraProps) {
  function Variant(props, parentRef) {
    return <ButtonLogic {...extraProps} {...props} ref={parentRef} />;
  }
  // eslint-disable-next-line no-func-assign
  Variant = forwardRef(Variant);
  Variant.displayName = displayName;
  return Variant;
}

const Button = makeVariant('Button', { size: 'normal' });

Button.Small = makeVariant('Button', { size: 'small' });
Button.Large = makeVariant('Button', {
  size: 'large',
});

Button.IconOnLeft = makeVariant('Button', { iconPosition: 'left' });
Button.IconOnRight = makeVariant('Button', { iconPosition: 'right' });

Button.Run = makeVariant('Button', { action: 'run' });
Button.Cancel = makeVariant('Button', { action: 'cancel' });
Button.Delete = makeVariant('Button', { action: 'delete' });
Button.Rerun = makeVariant('Button', { action: 'rerun' });
Button.Save = makeVariant('Button', { action: 'save' });
Button.Create = makeVariant('Button', { action: 'create' });

Button.DialogClose = makeVariant('Button', {
  icon: <Icon name="close" />,
  size: 'large',
});

Button.AddStepBtn = styled(
  makeVariant('Button', { round: true, icon: <Icon name="plus" /> })
)`
  border: 1px solid ${colors.gray_border};
  background: ${colors.gray11};
  margin: 0;
  padding: 0;
  width: 35px;
  height: 35px;
  svg {
    margin-top: -2px;
  }
  z-index: 100;
`;

Button.LeftSidebarCreateBtn = styled(
  makeVariant('Button', {
    action: 'create',
    iconPosition: 'right',
    justifyContent: 'space-between',
  })
)`
  width: 100%;
  margin: 0;

  padding: 8px 12px;
  span {
    height: 20px;
    font-size: 24px;
    font-weight: 200;
    margin-top: -13px;
    margin-left: 5px;
    font-family: 'Helvetica Neue', sans-serif;
  }
`;

Button.ListPageCreateBtn = styled(
  makeVariant('Button', {
    action: 'create',
    iconPosition: 'right',
    justifyContent: 'space-between',
  })
)`
  position: absolute;
  bottom: 50px;
  left: 40px;
  min-height: 34px;
  margin: 0;
  padding: 8px 10px 8px 12px;
  border-radius: 45px;
  span {
    height: 20px;
    font-size: 22px;
    font-weight: 200;
    margin-top: -11px;
    margin-left: 10px;
    font-family: 'Helvetica Neue', sans-serif;
  }
`;

Button.CreateFunxBtn = styled(Button.ListPageCreateBtn)`
  position: fixed;
`;

Button.MastheadHelpBtn = styled(
  makeVariant('Button', {
    size: 'small',
    textTransform: 'uppercase',
  })
)`
  color: white;
  border-color: ${colors.gray2b};
  font-size: 11px;
  :hover,
  :active,
  :focus,
  &.active {
    background-color: ${colors.gray2b};
  }
`;

const SidebarShowIcon = styled(MenuOpenRoundedIcon)`
  transform: scaleX(-1);
`;

Button.SidebarShowBtn = styled(
  makeVariant('Button', {
    icon: <SidebarShowIcon />,
  })
)`
  svg {
    color: ${colors.gray2b};
    font-size: 20px;
  }
  margin-right: 10px;
`;

Button.SidebarHideBtn = styled(
  makeVariant('Button', {
    icon: <MenuOpenRoundedIcon />,
  })
)`
  svg {
    color: ${colors.gray2b};
    font-size: 20px;
  }
  margin-right: 5px;
`;

Button.TableAddBtn = styled(
  makeVariant('Button', {
    icon: <Icon name="plus" />,
  })
)`
  :active,
  :focus,
  :hover {
    background-color: ${colors.active_blue};
    color: ${colors.create_blue};
    :disabled {
      color: ${colors.gray1};
        background-color: ${colors.gray3};
    }
  }
  ,

`;
Button.TableDeleteBtn = styled(
  makeVariant('Button', {
    icon: <Icon name="trash alternate outline" />,
  })
)`
  i,
  svg {
    font-size: 1.2em;
  }
`;

Button.EditBtn = makeVariant('Button', {
  icon: <Icon name="pencil" />,
  size: 'small',
});

Button.Dark = styled(makeVariant('Button', {}))`
  color: ${colors.gray1};
`;

Button.IconBtn = makeVariant('Button', {
  noBorder: true,
  iconSize: '1.2em',
  padding: '5px',
});

Button.Intercom = styled(
  makeVariant('Button', { round: true, icon: <Icon name="help" /> })
)`
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  right: 30px;

  background: ${colors.gray11};
  margin: 0;
  padding: 0;
  width: 55px;
  height: 55px;
  i {
    margin-top: -2px;
    font-size: 18px;
  }
`;

Button.TestFlowBtn = styled(
  makeVariant('Button', { icon: <Icon name="lab" />, iconPosition: 'left' })
)`
  color: ${colors.gray1};
  font-weight: bold;
  border: none;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px;
  &.active {
    background-color: ${colors.control_yellow};
  }
`;

Button.ToggleContent = styled(makeVariant('Button', { round: true }))`
  border: none;
  color: ${colors.gray1b};
  margin: 5px 0;
  font-size: 13px;
  font-weight: bold;
`;

export default Button;
