/** @jsx jsx */
/** @jsxRuntime classic */
import { useMemo, useCallback } from 'react';
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Dropdown } from 'semantic-ui-react';

import UnderlinedHeader from '<components>/UnderlinedHeader';
import Description from '<components>/Description';
import Link from '<components>/Link';
import Button from '<components>/Button';
import {
  FlexRowContainer,
  PageContent,
} from '<components>/NumbrzPageComponents';
import { RoundedContentWrapper } from '<components>/NumbrzVerticalEditor';
import Feature from '<components>/Feature';

import CatalogSettings from '<sections>/projects/components/CatalogSettings';

const StyledDropdown = styled(Dropdown)`
  padding-right: 12px;
`;

// need this for semantic-ui dropdown hidden content issue
const ContentWrapper = styled(RoundedContentWrapper)`
  overflow: visible;
`;

export default function ProjectOverview({
  project,
  updateProject,
  runProject,
  releaseTagValid,
  onCheckReleaseTag,
}) {
  const handleDescriptionChange = useCallback(
    async (description) => {
      if (description !== project.description) {
        await updateProject({
          variables: {
            input: {
              projectID: project.ID,
              description,
            },
          },
        });
      }
    },
    [project.ID, updateProject, project.description]
  );

  const handlePrimaryJobBindingChange = useCallback(
    async (e, data) => {
      if (data.value !== project.primaryJobID)
        await updateProject({
          variables: {
            input: {
              projectID: project.ID,
              primaryJobBindingID: data.value,
            },
          },
        });
    },
    [updateProject, project.ID, project.primaryJobID]
  );

  const jobDropdownOptions = useMemo(() => {
    const defaultOps = { text: 'None', value: null, key: 'None' };

    const jobOptions = project.jobs
      .filter((j) => !j.isBase)
      .map((s) => ({
        text: s.name,
        value: s.ID,
        key: s.ID,
      }));

    return [defaultOps, ...jobOptions];
  }, [project.jobs]);

  const primaryJob = project.primaryJobBinding;

  return (
    <PageContent>
      <ContentWrapper>
        <UnderlinedHeader>SOURCE MODEL</UnderlinedHeader>
        <Link internal to={`/models/${project.service.sourceProjectID}`}>
          {project.service.sourceProject.name}
        </Link>

        <UnderlinedHeader justifyContent="space-between">
          PRIMARY JOB
          {primaryJob && primaryJob.externalResultURL && (
            <Link.ExternalResults
              href={primaryJob.externalResultURL}
              size="small"
            />
          )}
        </UnderlinedHeader>
        <FlexRowContainer alignItems="center" justifyContent="flex-start">
          <StyledDropdown
            closeOnBlur
            value={project.primaryJobID}
            onChange={handlePrimaryJobBindingChange}
            options={jobDropdownOptions}
            placeholder="Select Job"
          />
          {project.primaryJobID && (
            <Button.Run size="small" onClick={runProject}>
              Run
            </Button.Run>
          )}
        </FlexRowContainer>

        <Description
          content={project.description}
          onChange={handleDescriptionChange}
          placeholder="No description provided"
          noContainer
          editable
        />

        <Feature name="PublishModel">
          <CatalogSettings
            project={project}
            updateProject={updateProject}
            releaseTagValid={releaseTagValid}
            onCheckReleaseTag={onCheckReleaseTag}
          />
        </Feature>
      </ContentWrapper>
    </PageContent>
  );
}
