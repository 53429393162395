import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '<src>/apollo/client';

import Dialog from '<components>/Dialog';
import Button from '<src>/components/Button';

import { DeployLibraryContent, GetProjects } from '<sections>/projects/queries';

import WaveSpinner from '<components>/WaveSpinner';
import { LibraryList } from '<sections>/catalog/styles';
import FunctionListItem from './FunctionListItem';
import { TargetPicker } from '<sections>/projects/pages/ProjectPage/components/CloneFunctionDialog';
import { FlexColumnContainer } from '<components>/NumbrzPageComponents';

export default function LibraryDialog({
  visible,
  onClose,
  catalogProjectID,
  libraryName = 'Library',
  libFunctions,
  activeFx,
  loading,
}) {
  const [deployContent] = useMutation(DeployLibraryContent);
  const [deployingAll, setDeployingAll] = useState(false);
  const [deployingFx, setDeployingFx] = useState([]);

  const [targetModel, setTargetModel] = useState();

  const { data: { projects = [] } = {}, loading: loadingProjects } =
    useQuery(GetProjects);

  // functions can only be deployed to project of type Standard
  const standardProjects = useMemo(
    () => projects.filter((p) => p.type === 'Standard'),
    [projects]
  );

  const deployFunctions = useCallback(
    async (fx, deployAll = false) => {
      let payload = {
        projectID: catalogProjectID,
        targetID: targetModel,
      };

      if (deployAll) {
        setDeployingAll(true);
        payload.functions = libFunctions.map((fx) => fx.ID);
        await deployContent({ variables: { input: payload } });
        setDeployingAll(false);
      } else if (fx) {
        setDeployingFx([...deployingFx, fx.ID]);
        payload.functions = [fx.ID];
        await deployContent({ variables: { input: payload } });
        setDeployingFx(deployingFx.filter((f) => f !== fx.ID));
      }
    },
    [catalogProjectID, deployContent, libFunctions, targetModel, deployingFx]
  );

  return (
    <Dialog
      visible={visible}
      onRequestClose={onClose}
      style={{ maxWidth: 'initial', minWidth: '50%' }}
    >
      <Dialog.Header>
        <Dialog.Headline>{libraryName}</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <Dialog.Body>
        {loading || loadingProjects ? (
          <WaveSpinner />
        ) : libFunctions.length > 0 ? (
          <FlexColumnContainer width="100%">
            <TargetPicker
              projects={standardProjects}
              targetProjectID={targetModel?.ID}
              setTargetProjectID={setTargetModel}
            />
            <LibraryList>
              {libFunctions.map((fx) => (
                <FunctionListItem
                  fx={fx}
                  expanded={fx.ID === activeFx}
                  onDeploy={deployFunctions}
                  btnDisabled={!targetModel}
                  deployingFxs={deployingFx}
                />
              ))}
            </LibraryList>
          </FlexColumnContainer>
        ) : (
          'No items found'
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Button.Run
          onClick={() => deployFunctions(null, true)}
          disabled={!targetModel || deployingAll}
        >
          {deployingAll ? 'Importing All...' : 'Import All'}
        </Button.Run>
      </Dialog.Footer>
    </Dialog>
  );
}
