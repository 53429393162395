import gql from 'graphql-tag';

import { JobItemFragment, DataMappingFragment } from '<sections>/jobs/queries';

export const ServiceProjectFragment = gql`
  fragment ServiceProjectFragment on Project {
    ID
    type
    name
    description
    primaryJobID
    catalog {
      currentReleaseID
      currentRelease {
        releaseTag
        releasedAt
      }
      tagLine
      deployIntro
      urlSlug
      tags
      category
      publishToCatalog
    }
    service {
      sourceProjectID
      sourceProject {
        ID
        name
      }
    }
    jobs {
      ...JobItemFragment
    }
  }

  ${JobItemFragment}
`;

export const GetServiceProject = gql`
  query GetServiceProject($ID: CommonID!) {
    project(ID: $ID) {
      ...ServiceProjectFragment
    }
  }

  ${ServiceProjectFragment}
`;

export const UpdateServiceProject = gql`
  mutation UpdateServiceProject($input: UpdateProjectInfoInput) {
    updateProjectInfo(input: $input) {
      ...ServiceProjectFragment
    }
  }

  ${ServiceProjectFragment}
`;

export const GetServiceJobDetail = gql`
  query GetServiceJobDetail($projectID: CommonID!) {
    jobs(projectID: $projectID) {
      ...JobItemFragment
      datamap {
        ...DataMappingFragment
        dataset {
          ID
          name
          deployKey
          tableset {
            ID
            name
            deployKey
            source {
              __typename
            }
          }
        }
      }
    }
  }

  ${JobItemFragment}
  ${DataMappingFragment}
`;

export const PromoteRelease = gql`
  mutation PromoteRelease($input: PromoteReleaseInput!) {
    promoteRelease(input: $input) {
      ...ServiceProjectFragment
    }
  }

  ${ServiceProjectFragment}
`;

export const DeploymentFragment = gql`
  fragment DeploymentFragment on Deployment {
    ID
    ownerID
    owner {
      ID
      display
    }
    createdAt
    sourceProjectID
    sourceReleaseID
    name
    fromCatalog
  }
`;

export const GetProjectDeployments = gql`
  query GetProjectDeployments($projectID: CommonID!) {
    projectDeployments(projectID: $projectID) {
      ...DeploymentFragment
    }
  }

  ${DeploymentFragment}
`;

export const UpgradeDeployment = gql`
  mutation UpgradeDeployment($ID: CommonID!) {
    upgradeDeployment(ID: $ID) {
      ...DeploymentFragment
    }
  }

  ${DeploymentFragment}
`;
