import React, { useState } from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { SearchBar, SearchBarWrapper, FilterBtn } from '../styles';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';
import FilterDialog from './FilterDialog';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { StepDropdownWrapper } from '<sections>/flows/flow-testing/styles';

export default function CatalogListControls({
  searchTerm,
  setSearchTerm,
  filters = {},
  setFilters,
  tags = [],
}) {
  const [focused, setFocused] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const filterCnt = filters.tags.length + filters.type.length;
  const filterLbl =
    filterCnt === 1
      ? `${filterCnt} Filter`
      : filterCnt > 1
        ? ` ${filterCnt} Filters`
        : 'Filters';

  return (
    <SearchBarWrapper className={focused && 'active'}>
      <FlexRowContainer
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <SearchRoundedIcon />
        <SearchBar
          type="search"
          placeholder="Search catalog"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </FlexRowContainer>
      <StepDropdownWrapper>
        <FilterBtn
          action="control"
          onClick={() => setShowFilters(!showFilters)}
          onMouseDown={(e) => e.preventDefault()}
          expanded={showFilters}
          active={filterCnt > 0}
        >
          <FilterListRoundedIcon />
          {filterLbl}
        </FilterBtn>
        <FilterDialog
          visible={showFilters}
          setVisible={setShowFilters}
          filters={filters}
          setFilters={setFilters}
          tags={tags}
        />
      </StepDropdownWrapper>
    </SearchBarWrapper>
  );
}
