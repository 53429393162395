import React, { Fragment, useCallback, useState } from 'react';
import fp from 'lodash/fp';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import * as colors from '<components>/colors';
import DataPicker from '<components>/DataPicker';

import Button from '<src>/components/Button';
import SchemaElementInput from '<components>/SchemaElementInput';
import UnderlinedHeader from '<components>/UnderlinedHeader';
import Description from '../Description';
import { Dropdown } from 'semantic-ui-react';
import {
  EditableHeader,
  EditableField,
  FlexRowContainer,
} from '<components>/NumbrzPageComponents';
import { RoundedContentWrapper } from '<components>/NumbrzVerticalEditor';
import { ConfigTable } from '<sections>/flows/styles';
import ActionMenu from '../ActionMenu';
import ColumnTypePicker from '<sections>/flows/ColumnTypePicker';
import DatePicker from 'react-datepicker';
import {
  StyledLabel,
  DataSeparator,
} from '<sections>/data/pages/TablesetPage/styles';
import dateMacros from '<sections>/data/pages/TablesetPage/dateMacros';
import moment from 'moment';
import { getQBTableTitle } from '<sections>/data/pages/TablesetPage/api/useTablesetState';

const StyledDropdown = styled(Dropdown)`
  background: white;
  margin-left: -5px;
  border: none !important;
  .text {
    font-weight: normal;
  }
`;

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const BaseHeader = styled('div')`
  margin-bottom: 10px;
`;

const HeaderBar = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > button {
    font-size: 12px;
  }
`;

const TableName = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 13px;
  margin-left: 10px;
  & > h4,
  & > h5 {
    font-size: 11px;
    font-weight: normal;
    color: ${colors.gray1b};
  }

  & > * {
    margin-top: 2px !important;
    margin: 0px !important;
  }

  & > *:first-child${ignoreSsrWarning} {
    margin-top: 0px !important;
  }
`;

const ConfirmText = styled('span')`
  transition:
    width 300ms ease-in-out,
    opacity 300ms ease-in-out;

  width: 0;
  opacity: 0;
  overflow-x: hidden;

  &.expanded {
    width: 50px;
    opacity: 100%;
  }
`;

const columnTypeOptions = [
  { text: 'String', value: 'String' },
  { text: 'Integer', value: 'Integer' },
  { text: 'Decimal', value: 'Decimal' },
  { text: 'Date', value: 'Date' },
  { text: 'Date and Time', value: 'DateTime' },
  { text: 'Date and Time (no time zone)', value: 'DateTimeNTZ' },
  { text: 'Boolean', value: 'Boolean' },
  { text: 'Unknown', value: 'Unknown', disabled: true },
];

const columnTypeLabels = {
  DateTime: 'Date and Time',
  DateTimeNTZ: 'Date and Time (no time zone)',
};

const TableTypeLabel = ({ table }) => {
  const { __typename: tableType } = table.source;
  if (tableType === 'GoogleSheet') {
    return <h4>Google Sheet</h4>;
  }
  if (tableType === 'SnowflakeTable') {
    return <h4>Snowflake Table</h4>;
  }
  if (tableType === 'NumbrzTable' && table.usage === 'WorkingData') {
    return <h4>Working Table</h4>;
  }

  return null;
};

const TableColumn = ({
  readOnly,
  column,
  showLabel,
  editLabel,
  showColumnID,
  allowTypeChange,
  onUpdate,
  onDelete,
  isNumbrzTable,
}) => {
  return (
    <tr>
      {showColumnID && <td>{column.externalID}</td>}
      {showLabel && (
        <td>
          {editLabel ? (
            <EditableField
              singleClick
              value={column.label}
              disabled={readOnly && !onUpdate}
              onSubmit={(e) => onUpdate(column.ID, 'label', e.value)}
            />
          ) : (
            column.label
          )}
        </td>
      )}
      <td className={`type ${column.type === 'Unknown' ? 'error' : ''}`}>
        {onUpdate && !readOnly && allowTypeChange ? (
          <ColumnTypePicker
            type={column.type}
            onChange={(e, { type }) => onUpdate(column.ID, 'type', type)}
            columnTypeOptions={columnTypeOptions}
          />
        ) : (
          columnTypeLabels[column.type] || column.type
        )}
      </td>
      <td className="note">
        <EditableField
          singleClick
          value={column.description}
          disabled={readOnly && !onUpdate}
          onSubmit={(e) => onUpdate(column.ID, 'description', e.value)}
        />
      </td>
      {isNumbrzTable && !readOnly && onDelete && (
        <td className="btn">
          <Button.TableDeleteBtn onClick={() => onDelete(column.ID)} />
        </td>
      )}
    </tr>
  );
};

export default function DataTablePane({
  headerComp,
  hideDescription,
  hideHeader,
  readOnly,
  showTableType,
  table,
  onAddColumn,
  onDelete,
  onDeleteColumn,
  onSyncColumns,
  onUpdate,
  updateTable,
  onUpdateColumn,
  onUpdateColumns,
  onTruncateTable,
  requiresDateInput = () => {},
  isValidExt = () => {},
}) {
  const [syncing, setSyncing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deletingData, setDeletingData] = useState(false);
  const [showImportDialog = false, setShowImportDialog] = useState();

  const fromDateProp = fp.getOr(null, 'source.fromTo.fromDate', table);
  const toDateProp = fp.getOr(null, 'source.fromTo.toDate', table);
  const dateMacroProp =
    fromDateProp || toDateProp
      ? 'custom'
      : fp.getOr(null, 'source.dateMacro', table);

  const [dateMacro, setDateMacro] = useState(dateMacroProp);
  const [fromDate, setFromDate] = useState(
    fromDateProp ? new Date(fromDateProp * 1) : null
  );
  const [toDate, setToDate] = useState(
    toDateProp ? new Date(toDateProp * 1) : null
  );

  const syncColumns = async () => {
    setSyncing(true);
    await onSyncColumns();
    setSyncing(false);
  };

  const truncateTable = async () => {
    await onTruncateTable();
    setTimeout(() => setDeletingData(false), 500);
  };

  const handleImportFields = (dataTable) => {
    if (dataTable) {
      const newColumns = dataTable.columns.map((col) => ({
        label: col.label,
        type: col.type,
      }));
      onUpdateColumns(newColumns);
      setShowImportDialog(false);
    }
  };

  const handleUpdateDates = useCallback(async () => {
    setUpdating(true);

    const tableEntityID = fp.getOr(undefined, 'source.entityID', table);

    const fromTo = fromDate
      ? {
          fromDate: moment(fromDate).format('x'),
          toDate: toDate ? moment(toDate).format('x') : null,
        }
      : null;
    if (tableEntityID)
      await updateTable({
        variables: {
          input: {
            ID: table.ID,
            entityID: tableEntityID,
            dateMacro: fromDate || toDate ? null : dateMacro,
            fromTo,
            name: getQBTableTitle(tableEntityID, dateMacro, toDate, fromDate),
          },
        },
      });

    setUpdating(false);
  }, [table, dateMacro, fromDate, toDate, updateTable]);

  const history = useHistory();

  const { description, name } = table;

  const isNumbrzTable = table.source.__typename === 'NumbrzTable';
  const HeaderComp = headerComp || BaseHeader;

  return (
    <Fragment>
      {!hideHeader && (
        <HeaderComp>
          <HeaderBar>
            <TableName>
              <EditableHeader
                value={name}
                disabled={readOnly && !onUpdate}
                singleClick
                onSubmit={(e) => {
                  onUpdate('name', e.value);
                  history.replace({
                    search: '',
                  });
                }}
              >
                {name}
              </EditableHeader>

              {showTableType && <TableTypeLabel table={table} />}
            </TableName>
            {!readOnly && onDelete && (
              <Button.Cancel
                size="small"
                onClick={() => {
                  if (deleting) {
                    onDelete();
                  } else {
                    setDeleting(true);
                  }
                }}
                onBlur={() => setDeleting(false)}
                textTransform="none"
              >
                <ConfirmText className={deleting ? 'expanded' : undefined}>
                  Confirm
                </ConfirmText>
                {isNumbrzTable ? 'Delete Table' : 'Disconnect'}
              </Button.Cancel>
            )}
          </HeaderBar>
        </HeaderComp>
      )}

      <RoundedContentWrapper padding="15px" style={{ overflow: 'unset' }}>
        {!hideDescription && (description || !readOnly) && (
          <Description
            editable={!readOnly && !!onUpdate}
            content={description}
            onChange={(desc) => onUpdate('description', desc)}
            rows={4}
            noContainer
            placeholder="No description provided"
          />
        )}
        {requiresDateInput(table) && (
          <Fragment>
            <UnderlinedHeader
              justifyContent="space-between"
              margin="10px 0 5px 0"
            >
              <span>Date Range</span>
            </UnderlinedHeader>
            <FlexRowContainer
              alignItems="center"
              justifyContent="space-between"
            >
              <FlexRowContainer alignItems="center" justifyContent="flex-start">
                <StyledLabel>
                  <StyledDropdown
                    disabled={readOnly}
                    options={dateMacros}
                    value={dateMacro}
                    placeholder="None"
                    onChange={(e, data) => {
                      setDateMacro(data.value);
                      setFromDate(null);
                      setToDate(null);
                    }}
                  />
                </StyledLabel>

                {dateMacro === 'custom' && (
                  <FlexRowContainer
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <DataSeparator />
                    <StyledLabel>
                      <span>Start Date</span>
                      <DatePicker
                        disabled={readOnly}
                        selected={fromDate}
                        onChange={(date) => {
                          setFromDate(date);
                        }}
                        dateFormat="MM/dd/yyyy"
                      />
                    </StyledLabel>
                    <DataSeparator />
                    <StyledLabel>
                      <span>End Date</span>
                      <DatePicker
                        disabled={readOnly}
                        selected={toDate}
                        onChange={(date) => {
                          setToDate(date);
                        }}
                        dateFormat="MM/dd/yyyy"
                      />
                    </StyledLabel>
                  </FlexRowContainer>
                )}
              </FlexRowContainer>
              {!readOnly && (
                <Button.Create
                  size="small"
                  onClick={handleUpdateDates}
                  disabled={updating || !isValidExt(table, dateMacro, fromDate)}
                >
                  {updating ? 'Updating...' : 'Update'}
                </Button.Create>
              )}
            </FlexRowContainer>
          </Fragment>
        )}
        {isNumbrzTable && !readOnly && onAddColumn && (
          <Fragment>
            <UnderlinedHeader justifyContent="space-between">
              <span>Add Column</span>
              {onUpdateColumns && table.columns.length === 0 && (
                <Button
                  action="control"
                  onClick={() => setShowImportDialog(true)}
                >
                  Add schema
                </Button>
              )}
            </UnderlinedHeader>
            <SchemaElementInput
              defaultValue="String"
              typeOptions={columnTypeOptions}
              addSchemaElement={onAddColumn}
            />
          </Fragment>
        )}

        <UnderlinedHeader justifyContent="space-between" margin="10px 0 5px 0">
          <span>Columns</span>
          {!isNumbrzTable && onSyncColumns && (
            <ActionMenu
              options={[
                ActionMenu.syncOption({
                  onSelect: () => syncColumns(),
                  disabled: syncing,
                  text: 'Refresh columns',
                }),
              ]}
            />
          )}
          {isNumbrzTable &&
            table.columns &&
            table.columns.length > 0 &&
            !readOnly && (
              <Button.Cancel
                size="small"
                onClick={() => {
                  if (deletingData) {
                    truncateTable();
                  } else {
                    setDeletingData(true);
                  }
                }}
                onBlur={() => setDeletingData(false)}
              >
                <ConfirmText className={deletingData ? 'expanded' : undefined}>
                  Confirm
                </ConfirmText>
                Delete Data
              </Button.Cancel>
            )}
        </UnderlinedHeader>
        {table.columns && table.columns.length > 0 && (
          <ConfigTable margin="0px" width="100%">
            <thead>
              <tr>
                <th>Column</th>
                {!isNumbrzTable && <th>Label</th>}
                <th>Type</th>
                <th className="note">Note</th>
                {isNumbrzTable && !readOnly && onDeleteColumn && <th />}
              </tr>
            </thead>
            <tbody>
              {table.columns.map((column, idx) => (
                <TableColumn
                  readOnly={readOnly}
                  key={idx}
                  column={column}
                  showColumnID={!isNumbrzTable}
                  showLabel={true}
                  editLabel={isNumbrzTable}
                  allowTypeChange={isNumbrzTable ? false : true}
                  onUpdate={onUpdateColumn}
                  onDelete={onDeleteColumn}
                  isNumbrzTable={isNumbrzTable}
                />
              ))}
            </tbody>
          </ConfigTable>
        )}
      </RoundedContentWrapper>

      {!readOnly && showImportDialog && (
        <DataPicker.UserData.Dialog
          title="Add Schema"
          visible={showImportDialog ? 1 : 0}
          onClose={() => setShowImportDialog(false)}
          onSelect={handleImportFields}
        />
      )}
    </Fragment>
  );
}
