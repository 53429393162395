import React from 'react';
import Card from '<components>/Card';
import Button from '<components>/Button';
import * as styles from '../../styles';

export default function LibraryListItem({ item, onClick }) {
  return (
    <styles.LibraryCardListItem onClick={onClick}>
      <Card.Header>
        <Card.Headline>{item.name}</Card.Headline>
      </Card.Header>
      <Card.Body />
      <Card.Footer>
        <Button size="small" onClick={onClick}>
          Details
        </Button>
      </Card.Footer>
    </styles.LibraryCardListItem>
  );
}
