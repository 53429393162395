import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Input, Message } from 'semantic-ui-react';

import Button from '<components>/Button';
import Dialog from '<components>/Dialog';
import { MarkdownEditor } from '<components>/EditableMarkdown';

export default function AddReleaseDialog({
  project,
  visible,
  releaseTagValid,
  onClose,
  onCreate,
  onCheckReleaseTag = () => {},
}) {
  const tagRef = useRef();
  const [releaseTag, setReleaseTag] = useState('');
  const [releaseNotes, setReleaseNotes] = useState('');
  const [error, setError] = useState(null);
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    if (!visible) {
      setReleaseTag('');
      setReleaseNotes('');
      setError(null);
      setCreating(false);
    } else {
      tagRef.current.focus();
    }
  }, [visible]);

  useEffect(() => {
    onCheckReleaseTag(releaseTag);
  }, [onCheckReleaseTag, releaseTag]);

  const doCreateRelease = useCallback(async () => {
    setCreating(true);
    const { errors } = await onCreate({
      releaseTag,
      releaseNotes,
      promote: true,
    });
    setCreating(false);
    if (errors && errors.length > 0) {
      setError(errors[0].message);
    } else {
      onClose();
    }
  }, [onClose, onCreate, releaseNotes, releaseTag]);

  const isValid = !error && releaseTag && releaseNotes && releaseTagValid;

  return (
    <Dialog
      onRequestClose={onClose}
      visible={visible}
      style={{ minWidth: '650px' }}
    >
      <Dialog.Header>
        <Dialog.Headline>Create a release</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <Dialog.Body style={{ minWidth: '450px' }}>
        {error ? (
          <Message error>{error}</Message>
        ) : (
          <>
            {releaseTagValid === false ? (
              <Message error>
                The release tag {releaseTag} has previously been published
              </Message>
            ) : null}
            <Form>
              <Form.Field>
                <label htmlFor="releaseTag">Release Tag (required)</label>
                <Input
                  id="releaseTag"
                  ref={tagRef}
                  value={releaseTag}
                  onChange={(e) => setReleaseTag(e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>Release Notes (required)</label>
                <MarkdownEditor
                  content={releaseNotes}
                  onChange={(e) => setReleaseNotes(e.target.value)}
                />
              </Form.Field>
            </Form>
          </>
        )}
      </Dialog.Body>
      <Dialog.Buttons>
        <Button
          disabled={!isValid || creating}
          onClick={doCreateRelease}
          action="deploy"
        >
          {creating ? 'Creating...' : 'Create'}
        </Button>
      </Dialog.Buttons>
    </Dialog>
  );
}
