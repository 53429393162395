import React, { useState, useCallback, useMemo, useEffect } from 'react';
import fp from 'lodash/fp';
import { FlatListHdrRow, FlatListRowSelectable } from './styles';
import Link from '<components>/Link';
import Button from '<components>/Button';
import ActionMenu from '<components>/ActionMenu';
import { formatDataSourceLabel } from '<sections>/data/utils';
import { ErrorChiclet, NumbrzTooltip } from '<components>/NumbrzButtons';

export function HdrRow(entityType) {
  switch (entityType) {
    case 'model':
      return (
        <FlatListHdrRow className="model-row">
          <div>Name</div>
          <div />
          <div />
          <div />
        </FlatListHdrRow>
      );
    case 'job':
      return (
        <FlatListHdrRow className="job-row">
          <div>Name</div>
          <div />
          <div />

          <div />
        </FlatListHdrRow>
      );
    case 'function':
    case 'flow':
      return (
        <FlatListHdrRow className="function-row">
          <div>Name</div>
          <div />
        </FlatListHdrRow>
      );
    case 'data':
      return (
        <FlatListHdrRow className="data-row">
          <div>Name</div>
          <div>Type</div>
          <div></div>
          <div />
          <div />
        </FlatListHdrRow>
      );
    case 'data-table':
      return (
        <FlatListHdrRow className="data-table-row">
          <div>Name</div>
          <div></div>

          <div />
        </FlatListHdrRow>
      );

    case 'job-group':
      return (
        <FlatListHdrRow className="job-group-row">
          <div>Name</div>
          <div />
          <div />
          <div />
        </FlatListHdrRow>
      );
    default:
      return (
        <FlatListHdrRow>
          <div>Name</div>
          <div />
        </FlatListHdrRow>
      );
  }
}

export function Row({
  entityType,
  item,
  baseURL,
  handleRunItem: onRun,
  setRunResult,
  handleDeleteItem,
  handleCloneItem,
  excludeDelete,
  excludeClone,
  history,
  itemsRunning,
}) {
  const [itemRunning, setItemRunning] = useState(false);
  const [showErrDialog, setShowErrDialog] = useState(false);

  useEffect(() => {
    if (entityType === 'job-group') {
      if (!itemRunning && itemsRunning.find((i) => i.groupID === item.ID))
        setItemRunning(true);
      if (itemRunning && !itemsRunning.find((i) => i.groupID === item.ID))
        setItemRunning(false);
    }
  }, [itemsRunning, item.ID, entityType, itemRunning]);

  const runnable = item.runnable || item?.primaryJobBinding?.runnable;
  const hideRunBtn = !['job', 'model', 'job-group'].includes(entityType)
    ? true
    : !runnable;

  const externalResultURL =
    item?.primaryJobBinding?.externalResultURL ||
    item.sourceURL ||
    item.externalResultURL ||
    item?.source?.url;

  const externalResultLink = useMemo(
    () =>
      externalResultURL ? (
        <Link.ExternalResults
          href={externalResultURL}
          size="small"
          label={['data', 'data-table'].includes(entityType) && 'Source'}
        />
      ) : null,
    [externalResultURL, entityType]
  );

  const handleRunItem = useCallback(async () => {
    setItemRunning(true);
    let res;
    let payload;

    if (entityType === 'job' || entityType === 'model') {
      const jobID = entityType === 'job' ? item.ID : item.primaryJobBinding.ID;
      const projectID = entityType === 'job' ? item.projectID : item.ID;

      payload = {
        variables: { input: { jobID } },
      };
      res = await onRun(payload);
      setRunResult(res);
      if (fp.getOr(undefined, 'data.runJob.success', res)) {
        const url = `/models/${projectID}/jobs/${jobID}/status/${res.data.runJob.runID}`;

        history.push(url);
      }
    }
    if (entityType === 'job-group') {
      res = await onRun({ variables: { jobGroupID: item.ID } });
      setRunResult(res);
      /*

      handle the case when a group is referencing a job that is already running
      show a pop up indicating that this job can't be run until the other finishes.
      */

      const runStateID = fp.getOr(undefined, 'data.startJobGroup', res);
      if (runStateID)
        history.push(`${baseURL}/${item.ID}/status/${runStateID}`);
    }

    setItemRunning(false);
  }, [
    entityType,
    item.ID,
    item.projectID,
    history,
    onRun,
    item.primaryJobBinding,
    baseURL,
    setRunResult,
  ]);

  switch (entityType) {
    case 'model':
      return (
        <FlatListRowSelectable className="model-row">
          <div className="name">
            <Link to={`${baseURL}/${item.ID}`} style={{ paddingLeft: 0 }}>
              {item.name}
            </Link>
          </div>
          <div>{externalResultLink}</div>
          <div>
            {!hideRunBtn && (
              <Button.Run
                size="small"
                title={`Run Model`}
                onClick={handleRunItem}
                disabled={!runnable || itemRunning}
              >
                {itemRunning ? 'Running...' : 'Run'}
              </Button.Run>
            )}
          </div>
          <div>
            <ActionMenu
              options={[
                ActionMenu.deleteOption({
                  onSelect: (e) => handleDeleteItem(e, item),
                }),
              ]}
            />
          </div>
        </FlatListRowSelectable>
      );
    case 'job':
      return (
        <FlatListRowSelectable className="job-row">
          <div className="name">
            <Link to={`${baseURL}/${item.ID}`} style={{ paddingLeft: 0 }}>
              {item.name}
            </Link>
          </div>
          <div>{externalResultLink}</div>

          <div>
            {!runnable && (
              <NumbrzTooltip
                content="This job contains errors."
                trigger={<ErrorChiclet>Issues</ErrorChiclet>}
              />
            )}
          </div>
          <div>
            <Button.Run
              size="small"
              title={`Run Job`}
              onClick={handleRunItem}
              disabled={itemRunning || !runnable}
            >
              {itemRunning ? 'Running...' : 'Run'}
            </Button.Run>
          </div>
          <div>
            <ActionMenu
              options={[
                ActionMenu.cloneOption({
                  exclude: excludeClone,
                  onSelect: (e) => handleCloneItem(e, item),
                }),
                ActionMenu.deleteOption({
                  onSelect: (e) => handleDeleteItem(e, item),
                  exclude: excludeDelete,
                }),
              ]}
            />
          </div>
        </FlatListRowSelectable>
      );
    case 'function':
    case 'flow':
      return (
        <FlatListRowSelectable className="function-row">
          <div className="name">
            <Link to={`${baseURL}/${item.ID}`} style={{ paddingLeft: 0 }}>
              {item.name}
            </Link>
          </div>
          <div>
            <ActionMenu
              options={[
                ActionMenu.cloneOption({
                  exclude: excludeClone,
                  onSelect: (e) => {
                    handleCloneItem(e, item);
                  },
                }),
                ActionMenu.deleteOption({
                  onSelect: (e) => handleDeleteItem(e, item),
                  exclude: excludeDelete,
                }),
              ]}
            />
          </div>
        </FlatListRowSelectable>
      );
    case 'data':
      return (
        <FlatListRowSelectable className="data-row">
          <div>
            <Link to={`/data/${item.ID}`}>{item.name}</Link>
          </div>
          <div className="sub-hdr">
            {formatDataSourceLabel(
              item.source?.__typename,
              item.connector?.name
            )}
          </div>
          <div>{externalResultLink}</div>
          <div>
            {fp.getOr([], 'state.issues', item).length > 0 && (
              <NumbrzTooltip
                content={() => item.state.issues.map((i) => <p>{i.desc}</p>)}
                trigger={
                  <ErrorChiclet marginRight="0" marginLeft="5px">{`${
                    item.state.issues.length
                  } ${
                    item.state.issues.length === 1 ? 'Issue' : 'Issues'
                  }`}</ErrorChiclet>
                }
              />
            )}
          </div>
          <div>
            <ActionMenu
              options={[
                ActionMenu.deleteOption({
                  onSelect: (e) => handleDeleteItem(e, item),
                }),
              ]}
            />
          </div>
        </FlatListRowSelectable>
      );
    case 'data-table':
      return (
        <FlatListRowSelectable className="data-table-row">
          <div>
            <Link to={`${baseURL}/${item.ID}`}>{item.name}</Link>
          </div>

          <div>{externalResultLink}</div>
          <div>
            <ActionMenu
              options={[
                ActionMenu.deleteOption({
                  onSelect: (e) => handleDeleteItem(e, item),
                }),
              ]}
            />
          </div>
        </FlatListRowSelectable>
      );

    case 'job-group':
      return (
        <FlatListRowSelectable className="job-group-row">
          <div className="name">
            <Link to={`/job-groups/${item.ID}`}>{item.name}</Link>
          </div>
          <div>{externalResultLink}</div>
          <div>
            {!runnable && (
              <NumbrzTooltip
                content="This job group contains errors."
                trigger={<ErrorChiclet>Issues</ErrorChiclet>}
              />
            )}
          </div>
          <div>
            <Button.Run
              size="small"
              title={`Run job group`}
              onClick={handleRunItem}
              disabled={itemRunning || !runnable}
            >
              {itemRunning ? 'Running...' : 'Run'}
            </Button.Run>
          </div>
          <div>
            <ActionMenu
              options={[
                ActionMenu.deleteOption({
                  onSelect: (e) => handleDeleteItem(e, item),
                }),
              ]}
            />
          </div>
        </FlatListRowSelectable>
      );
    default:
      return (
        <FlatListRowSelectable>
          <div>Name</div>
          <div />
        </FlatListRowSelectable>
      );
  }
}
