import React, { useEffect, useState } from 'react';
import fp from 'lodash/fp';
import styled from '@emotion/styled';
import { Message } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';
import { useMutation, useQuery } from '<src>/apollo/client';

import Button from '<components>/Button';
import * as colors from '<components>/colors';

import DeploymentSidebar from './sidebar';
import OwnerStep from './owner';
import GoogleSpreadsheetStep from './google';
import QuickbooksStep from './quickbooks';
import {
  CreateCredential,
  GetCredentials,
} from '<src>/sections/account/queries';

const WizardContainer = styled('div')`
  display: flex;
  & p {
    font-size: 1.2em;
  }
  max-width: 800px;
`;

const StepContent = styled('div')`
  min-height: 250px;
  min-width: 520px;
  justify-content: space-between;
  flex: 3;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  border-left: 1px solid ${colors.gray3} !important;
`;

const BtnToolbar = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 60px 0 0 0;
  button {
    margin-left: 10px;
  }
`;

const deployOpts = {
  GoogleSpreadsheet: {
    providerName: 'google',
    ignoreDeployKey: false,
    noCredReuse: false,
    paramTargetField: 'googleTemplate',
    StepComponent: GoogleSpreadsheetStep,
    stepText: 'Google Spreadsheet',
  },
  QuickbooksOnline: {
    providerName: 'quickbooks',
    ignoreDeployKey: true,
    noCredReuse: true,
    paramTargetField: 'quickbooks',
    makeProviderParams: (tsd) => ({ realmID: (tsd.creds || {}).realmId }),
    makeTargetParams: (tsd, credentialID) => ({
      realmID: (tsd.creds || {}).realmId,
      credentialID,
    }),
    StepComponent: QuickbooksStep,
    stepText: 'QuickBooks Online',
  },
  NumbrzDatabase: {
    noConfigStep: true,
  },
};

const empty = [];

function useDeploymentState(existingTablesets, initialDeployments, ownerID) {
  const [tablesetDeployments, setTablesetDeployments] =
    useState(initialDeployments);

  const { loading, data } = useQuery(GetCredentials, {
    variables: { ownerID },
    skip: !ownerID,
  });
  const userCredentials = loading || !ownerID ? empty : data.listCredentials;

  useEffect(() => {
    const ets = existingTablesets || [];

    setTablesetDeployments((tsDepl) =>
      tsDepl.map((tsd) => {
        let useExisting, creds;
        const { providerName, ignoreDeployKey, noCredReuse } =
          deployOpts[tsd.source.__typename];

        if (ignoreDeployKey) {
          const existing = ets.filter(
            (ts) => ts.source.__typename === tsd.source.__typename
          );
          if (existing.length === 1) {
            useExisting = existing[0].ID;
          }
        } else if (tsd.deployKey) {
          const existing = ets.find((ts) => ts.deployKey === tsd.deployKey);
          useExisting = existing ? existing.ID : null;
        }

        if (!noCredReuse) {
          const availCreds = userCredentials.filter(
            (c) => c.providerName === providerName
          );
          if (availCreds.length === 1) {
            creds = { credentialID: availCreds[0].ID };
          }
        }

        return { ...tsd, useExisting, creds };
      })
    );
  }, [existingTablesets, userCredentials]);

  const updateTablesetDeployment = (d, updates) =>
    setTablesetDeployments((tsDepl) =>
      tsDepl.map((tsd) =>
        tsd.snapshotID !== d.snapshotID ? tsd : { ...tsd, ...updates }
      )
    );

  const onUseExisting = (d, useExisting) =>
    updateTablesetDeployment(d, { useExisting });

  const onSetCreds = (d, creds) => updateTablesetDeployment(d, { creds });

  return { tablesetDeployments, onSetCreds, onUseExisting, userCredentials };
}

async function makeDeploymentParams(tsd, ownerID, createCredential) {
  const p = {
    snapshotID: tsd.snapshotID,
  };
  const { code, scope, redirectURL } = tsd.creds ? tsd.creds : {};

  if (!ownerID) {
    throw new Error('ownerID required');
  }

  const {
    paramTargetField,
    providerName,
    credName = 'oauthCode',
    makeProviderParams = () => undefined,
    makeTargetParams = (tsd, credentialID) => ({ credentialID }),
  } = deployOpts[tsd.source.__typename];

  if (tsd.useExisting) {
    p.useExisting = tsd.useExisting;
  } else if (providerName) {
    if (tsd.creds && tsd.creds.credentialID) {
      p[paramTargetField] = makeTargetParams(tsd, tsd.creds.credentialID);
    } else {
      const res = await createCredential({
        variables: {
          input: {
            ownerID,
            provider: {
              providerName,
              credName,
              credParams: {
                code,
                scope,
                redirectURL,
              },
              providerParams: makeProviderParams(tsd),
            },
          },
        },
      });
      const credentialID = fp.getOr(undefined, 'data.createCredential.ID', res);
      p[paramTargetField] = makeTargetParams(tsd, credentialID);
    }
  }

  return p;
}

export default function DeploymentWizard({
  accounts,
  existingTablesets,
  deployIntro,
  name,
  ownerID,
  tablesetDeployments: tsDeployments = [],
  onDeploy,
  onDoOAuth,
  onSetName,
  onSetOwnerID,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const { tablesetDeployments, onUseExisting, onSetCreds, userCredentials } =
    useDeploymentState(existingTablesets, tsDeployments, ownerID);

  const [createCredential] = useMutation(CreateCredential);

  const doDeploy = async () => {
    const tablesets = [];
    for (const tsd of tablesetDeployments) {
      const deployTsd = await makeDeploymentParams(
        tsd,
        ownerID,
        createCredential
      );
      tablesets.push(deployTsd);
    }

    onDeploy({
      ownerID,
      tablesets,
    });
  };

  const steps = [
    {
      value: 'intro',
      text: 'Introduction',
      noop: true,
      complete: true,
      content: (
        <div key="intro">
          {deployIntro ? <ReactMarkdown>{deployIntro}</ReactMarkdown> : null}
          <p>
            This wizard will help you configure this model to use your data.
            Click “Next” to get started.
          </p>
        </div>
      ),
    },
  ];
  if (name && onSetName) {
    steps.push({
      value: 'name',
      text: 'Model Name',
      noop: true,
      complete: true,
      content: (
        <div key="name">
          <p>Specify a name for the deployed model (optional)</p>
          <p>
            <input value={name} onChange={(e) => onSetName(e.target.value)} />
          </p>
        </div>
      ),
    });
  }
  if (accounts && onSetOwnerID) {
    steps.push({
      value: 'owner',
      text: 'Select Account',
      complete: !!ownerID,
      content: (
        <OwnerStep
          accounts={accounts}
          ownerID={ownerID}
          onSetOwnerID={onSetOwnerID}
        />
      ),
    });
  }
  tablesetDeployments.forEach((tsd, idx) => {
    const { providerName, stepText, StepComponent, noConfigStep } =
      deployOpts[tsd.source.__typename];

    if (noConfigStep) {
      return;
    }

    const onDoOAuthInner = (serviceType) => {
      onDoOAuth(serviceType, (creds) => onSetCreds(tsd, creds));
    };

    const isLastStep = idx === tablesetDeployments.length - 1;
    const authed = !!tsd.creds;
    steps.push({
      value: tsd.snapshotID,
      text: stepText,
      complete: tsd.useExisting || authed,
      content: (
        <StepComponent
          key={tsd.snapshotID}
          authed={authed}
          existingTablesets={existingTablesets}
          tablesetDeployment={tsd}
          onDoOAuth={onDoOAuthInner}
          isLastStep={isLastStep}
          onUseExisting={onUseExisting}
          onSetCreds={onSetCreds}
          userCreds={userCredentials.filter(
            (c) => c.providerName === providerName
          )}
        />
      ),
    });
  });

  const allComplete = steps.reduce(
    (complete, step) => complete && step.complete,
    true
  );

  const nextStep = () =>
    setActiveStep((current) => Math.min(current + 1, steps.length - 1));
  const prevStep = () => setActiveStep((current) => Math.max(current - 1, 0));

  const currentStep = steps[activeStep];

  return (
    <WizardContainer>
      <DeploymentSidebar
        steps={steps}
        activeStep={steps[activeStep].value}
        setActiveStep={(value) =>
          setActiveStep(steps.findIndex((s) => s.value === value))
        }
      />
      <StepContent>
        {currentStep.complete && !currentStep.noop ? (
          <Message
            size="small"
            color="green"
            content="Step has been successfully configured."
          />
        ) : null}
        {currentStep.content}
        <BtnToolbar>
          {activeStep > 0 ? <Button onClick={prevStep}>Previous</Button> : null}
          {steps.length > 1 && activeStep < steps.length - 1 ? (
            <Button onClick={nextStep}>Next</Button>
          ) : null}
          {activeStep === steps.length - 1 ? (
            <Button.Run onClick={doDeploy} disabled={!allComplete}>
              Deploy
            </Button.Run>
          ) : null}
        </BtnToolbar>
      </StepContent>
    </WizardContainer>
  );
}
