/** @jsx jsx */
/** @jsxRuntime classic */

import styled from '@emotion/styled';
import * as colors from '<components>/colors';
import Button from '<components>/Button';
import { TagItem } from '<components>/TagList';

export const SearchBar = styled('input')`
  width: 100%;

  outline: none;
  border: none;
  letter-spacing: 0.6px;
  font-size: 16px;
  background: transparent;
  margin-left: 5px;
  font-familt: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: ${colors.gray2};
  :active,
  :focus {
    color: ${colors.gray1};
  }
`;

export const SearchBarWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 6px 10px 6px 15px;
  border: 1px solid ${colors.gray_border};
  transition: border 100ms ease-out;
  background: white;
  border-radius: 45px;
  svg {
    color: ${colors.gray2};
    font-size: 24px;
    margin-bottom: -1px;
  }
  &.active {
    border: 1px solid ${colors.blue1};
    svg {
      color: ${colors.gray1};
    }
  }
`;

export const FilterBtn = styled(Button)`
  border-radius: 45px;
  padding: 5px 12px;
  svg {
    font-size: 20px;
    margin-right: 5px;
  }
  ${(props) =>
    props.active &&
    `
  color: ${colors.gray1};
  svg {
    color: ${colors.gray1} !important;
  }
  `}
`;

export const PopupDialog = styled('div')`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  padding: ${(props) => (props.padding ? props.padding : '5px 10px 10px 10px')};
  position: absolute;
  top: ${(props) => (props.top ? `${props.top}px` : '25px')};
  right: ${(props) => (props.right ? `${props.right}px` : '0px')};
  width: ${(props) => props.width && props.width};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '250px')};
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '150px')};

  z-index: 999;
  background: white;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  border-radius: 8px;
  border: 1px solid ${colors.gray_border};
  color: ${colors.gray1d};
  :focus {
    outline: none;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
    }
  }

  .ui.toggle.checkbox {
    width: unset;
    label {
      padding-top: 0;
      padding-right: 3rem;
    }
    label:before {
      width: 22px;
      height: 11.5px;
      margin-top: 5px;
      background-color: ${colors.gray2} !important;
    }
    label:after {
      margin-top: 5px;
      left: 3px;
      width: 8px;
      height: 8px;
      top: 1.5px;
    }
    input {
      width: unset;
      height: unset;
    }
    input:checked ~ label:after {
      left: 11px;
    }
  }
`;

export const SectionHdr = styled('h5')`
  color: ${colors.gray1};
  font-size: 11px;
  letter-spacing: 0.6px;
  margin-bottom: 8px;
`;

export const ModalFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 100%;
  width: 100%;
`;

export const ToggleBtn = styled(Button)`
  color: ${colors.gray1};
  border: 1px solid ${colors.gray_border};
  border-radius: 14px;
  background: white;
  :hover {
    border: 1px solid ${colors.gray1};
    background: white;
  }

  :focus {
    background: white;
  }

  text-transform: uppercase;
  margin: 0 10px 0 0;
  font-size: 11px;
  ${(props) =>
    props.selected &&
    `
  background: white;
    font-weight: bold;
    border: 1px solid ${colors.gray1};
  `}
`;

export const Tag = styled(TagItem)`
  background-color: white;
  color: ${colors.gray1};
  border: 1px solid ${colors.gray_border};
  letter-spacing: 1px;
  font-size: 13px;
  margin-right: 0;
  :hover {
    cursor: default;
  }
`;
