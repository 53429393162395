import React, { PureComponent, cloneElement } from 'react';
import T from 'prop-types';
import { Transition } from 'react-transition-group';

export default class Fade extends PureComponent {
  static defaultProps = {
    enterDuration: 400,
    exitDuration: 300,
  };

  static propTypes = {
    children: T.node.isRequired,
    enterDuration: T.number,
    exitDuration: T.number,
    in: T.bool,
  };

  render() {
    const { children, enterDuration, exitDuration, in: inProp } = this.props;

    const defaultStyle = {
      transition: `${exitDuration}ms ease-in`,
      transitionProperty: 'opacity, transform',
    };

    const transitionStyles = {
      entering: { opacity: 0, transform: 'scale(0.8)' },
      entered: { opacity: 1 },
      exiting: { opacity: 0, transform: 'scale(0.8)' },
    };

    const timeout = { enter: enterDuration, exit: exitDuration };

    return (
      <Transition in={inProp} timeout={timeout}>
        {status => {
          if (status === 'exited') return null;

          const style = { ...defaultStyle, ...transitionStyles[status] };
          return cloneElement(children, { style });
        }}
      </Transition>
    );
  }
}
