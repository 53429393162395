import React from 'react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';

const StyledHeader = styled('h4')`
  width: 100%;
  margin: ${(props) =>
    props.margin ? props.margin : '10px 0 2px 0 !important'};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  ${(props) => props.uppercase && { textTransform: 'uppercase' }};
  display: flex;
  align-items: baseline;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  border-bottom: 1px solid ${colors.gray_border};
  ${(props) => props.borderWidth && { borderWidth: props.borderWidth }};

  span {
    text-transform: uppercase;
    font-size: 11px;
  }

  color: ${colors.gray1b};
  letter-spacing: 0.5px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '11px')};
`;

const Controls = styled('div')`
  display: flex;
  justify-content: space-around;
`;

const UnderlinedHeader = ({ as = 'h5', ...props }) => {
  const Comp = StyledHeader.withComponent(as);
  return <Comp {...props} />;
};

UnderlinedHeader.Controls = Controls;

export default UnderlinedHeader;
