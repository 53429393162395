import React from 'react';

import * as styles from '../../styles';
import CatalogStats from '../../CatalogStats';

const typeLabel = {
  DeploySource: 'Template',
  DeployService: 'Service',
};

export default function CatalogItem({
  catalogProject,
  searchPattern,
  searchTerm,
  onDeploy,
}) {
  return (
    <styles.ListItem to={`/catalog/${catalogProject.ID}`}>
      <styles.ListItem.Header>
        <h2>{catalogProject.name}</h2>
        <h3>{typeLabel[catalogProject.type]}</h3>
      </styles.ListItem.Header>
      <styles.ListItem.Body>
        <CatalogStats catalogProject={catalogProject} small center />
        <div className="tag-line">{catalogProject.tagLine}</div>
      </styles.ListItem.Body>
      <styles.ListItem.Footer justifyContent="flex-start">
        <div>{catalogProject.owner.display}</div>
        <styles.OrgIconCircle />
      </styles.ListItem.Footer>
    </styles.ListItem>
  );
}
