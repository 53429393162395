import gql from 'graphql-tag';

import { FlowListItemFragment } from '<sections>/flows/api/fragments';
import { JobFragment, JobItemFragment } from '<sections>/jobs/queries';
import { CatalogProjectFragment } from '<sections>/catalog/queries';

export const GetViewer = gql`
  query GetViewer {
    viewer {
      account {
        ID
        display
        stripeProductID
        stripeCustomerID
        primaryAccount {
          ID
          ... on Org {
            display
            stripeProductID
            stripeCustomerID
          }
          ... on User {
            fullName
            stripeProductID
            stripeCustomerID
          }
        }
        membership {
          account {
            ID
            display
          }
          roles
        }
      }
    }
  }
`;

export const ProjectFragment = gql`
  fragment ProjectFragment on Project {
    ID
    type
    name
    description
    sourceTemplateID
    templateSpreadsheetURL
    readOnly
    primaryJobID
    primaryJobBinding {
      ID
      name
      runnable
      externalResultURL
    }
    deployment {
      sourceProjectID
      type
      fromCatalog
      upgradeAvailable {
        releasedAt
        releaseTag
        releaseNotes
      }
    }
    catalog {
      currentReleaseID
      tagLine
      urlSlug
      tags
      category
      publishToCatalog
    }
    template {
      isLibrary
    }
  }
`;

export const FunctionV3ItemFragment = gql`
  fragment FunctionV3ItemFragment on FunctionV3 {
    ID
    name
    description
  }
`;

export const GetProjects = gql`
  query GetProjects($accountID: CommonID, $types: [ProjectType]) {
    projects(filter: { ownerID: $accountID, types: $types }) {
      ...ProjectFragment
    }
  }

  ${ProjectFragment}
`;

export const GetProject = gql`
  query GetProject($ID: CommonID!) {
    project(ID: $ID) {
      ...ProjectFragment
    }
  }

  ${ProjectFragment}
`;

export const GetProjectName = gql`
  query GetProjectName($ID: CommonID!) {
    project(ID: $ID) {
      ID
      name
    }
  }
`;

export const UpdateProjectInfo = gql`
  mutation UpdateProjectInfo($input: UpdateProjectInfoInput) {
    updateProjectInfo(input: $input) {
      ...ProjectFragment
    }
  }

  ${ProjectFragment}
`;

export const CreateProject = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      ID
    }
  }
`;

export const DeleteProject = gql`
  mutation DeleteProject($ID: CommonID!) {
    removeProject(ID: $ID)
  }
`;

export const GetDataTables = gql`
  query GetDataTables {
    dataTables {
      tables {
        ID
        name
        description
        projectIDs {
          ID
        }
      }
    }
  }
`;

export const GetDataTable = gql`
  query GetDataTable($ID: CommonID!) {
    dataTable(ID: $ID) {
      ID
      name
      description
      state {
        ready
        issues {
          code
          desc
        }
      }
      columns {
        ID
        label
        description
        type
      }
      externalColumns {
        externalID
        label
        description
        type
      }
    }
  }
`;

export const UpdateDataTableColumns = gql`
  mutation UpdateDataTableColumns($input: UpdateDataTableColumnsInput!) {
    updateDataTableColumns(input: $input) {
      ID
      name
      description
      columns {
        ID
        externalID
        label
        description
        type
      }
      externalColumns {
        externalID
        label
        description
        type
      }
      state {
        ready
        issues {
          code
          desc
        }
      }
    }
  }
`;

export const GetProjectFunctionsV3 = gql`
  query GetProjectFunctionsV3($ID: CommonID!) {
    project(ID: $ID) {
      ID
      readOnly
      functionsV3 {
        ...FunctionV3ItemFragment
      }
    }
  }

  ${FunctionV3ItemFragment}
`;

export const DeleteFuncV3 = gql`
  mutation DeleteFuncV3($ID: CommonID!) {
    deleteFuncV3(ID: $ID) {
      success
    }
  }
`;

export const GetProjectJobs = gql`
  query GetProjectJobs($ID: CommonID!) {
    project(ID: $ID) {
      ID
      name
      jobs {
        ...JobItemFragment
      }
    }
  }

  ${JobItemFragment}
`;

export const DeleteJob = gql`
  mutation DeleteJob($jobID: CommonID!) {
    deleteJob(jobID: $jobID)
  }
`;

export const GetProjectJobDetails = gql`
  query GetProjectJobDetails($ID: CommonID!) {
    project(ID: $ID) {
      ID
      jobs {
        ...JobFragment
        datamap {
          datasetID
          tableID
          dataset {
            ID
            name
            deployKey
            tableset {
              ID
              name
              deployKey
              source {
                __typename
              }
            }
          }
        }
      }
    }
  }

  ${JobFragment}
`;

export const ProjectVersionFragment = gql`
  fragment ProjectVersionFragment on ProjectVersion {
    ID
    comment
    createdAt
    createdBy {
      display
    }
    isNew
  }
`;

export const GetProjectVersions = gql`
  query GetProjectVersions($ID: CommonID!) {
    project(ID: $ID) {
      ID
      versions {
        ...ProjectVersionFragment
      }
    }
  }

  ${ProjectVersionFragment}
`;

export const GetProjectFlows = gql`
  query GetProjectFlows($ID: CommonID!) {
    project(ID: $ID) {
      ID
      flows {
        ...FlowListItemFragment
      }
    }
  }

  ${FlowListItemFragment}
`;

export const CreateFlowV2 = gql`
  mutation CreateFlowV2($input: CreateFlowInput!) {
    createFlow: createFlowV2(input: $input) {
      flow {
        ID
      }
    }
  }
`;

export const DeleteFlow = gql`
  mutation DeleteFlow($flowID: CommonID!) {
    deleteFlow(flowID: $flowID)
  }
`;

export const CancelFlow = gql`
  mutation CancelFlow($runID: String!) {
    cancelFlow(runID: $runID) {
      success
    }
  }
`;

export const RecentRuns = gql`
  query RecentRuns($input: RecentRunsInput) {
    recentRuns(input: $input) {
      runID
      runByName
      containerID
      bindingID
      status
      elapsed
      bindingName
      started
      externalResultURL
    }
  }
`;

export const CloneFlow = gql`
  mutation CloneFlow($input: CloneFlowInput) {
    cloneFlow(input: $input) {
      ...FlowListItemFragment
    }
  }
  ${FlowListItemFragment}
`;

export const CloneFunction = gql`
  mutation CloneFunction($input: CloneFuncV3Input!) {
    cloneFuncV3(input: $input) {
      ...FunctionV3ItemFragment
    }
  }

  ${FunctionV3ItemFragment}
`;

export const DeploymentSpecFragment = gql`
  fragment DeploymentSpecFragment on DeploymentSpec {
    defaultName
    deployIntro
    tablesets {
      snapshotID
      deployKey
      source {
        __typename
      }
    }
  }
`;

export const GetDeploymentSpec = gql`
  query GetDeploymentSpec($input: DeploymentSpecInput!) {
    deploymentSpec(input: $input) {
      ...DeploymentSpecFragment
    }
  }

  ${DeploymentSpecFragment}
`;

const ProjectDeploymentFragment = gql`
  fragment ProjectDeploymentFragment on Project {
    ID
    ownerID
    name
    description
    deployment {
      state {
        status
        err {
          code
          message
        }
      }
      tables {
        ...DeploymentMappingFragment
      }
      flows {
        ...DeploymentMappingFragment
      }
      jobs {
        ...DeploymentMappingFragment
      }
    }
  }

  fragment DeploymentMappingFragment on DeploymentMapping {
    snapID
    entityID
  }
`;

export const DeployService = gql`
  mutation DeployService($input: DeployServiceInput!) {
    deployService(input: $input) {
      ...ProjectDeploymentFragment
    }
  }

  ${ProjectDeploymentFragment}
`;

export const DeployTemplate = gql`
  mutation DeployTemplate($input: DeployTemplateInput!) {
    deployTemplate(input: $input) {
      ...ProjectDeploymentFragment
    }
  }

  ${ProjectDeploymentFragment}
`;

export const PollProjectDeployment = gql`
  query PollProjectDeployment($ID: CommonID!) {
    project(ID: $ID) {
      ...ProjectDeploymentFragment
    }
  }

  ${ProjectDeploymentFragment}
`;

export const CreateProjectVersion = gql`
  mutation CreateProjectVersion($input: CreateProjectVersionInput!) {
    createProjectVersion(input: $input) {
      ...ProjectVersionFragment
    }
  }

  ${ProjectVersionFragment}
`;

export const DeleteProjectVersion = gql`
  mutation DeleteProjectVersion($ID: CommonID!) {
    deleteProjectVersion(ID: $ID)
  }
`;

export const PublishProject = gql`
  mutation PublishProject($input: PublishInput!) {
    publishProject(input: $input) {
      ...CatalogProjectFragment
    }
  }
  ${CatalogProjectFragment}
`;

export const UnpublishProject = gql`
  mutation UnpublishProject($ID: CommonID!) {
    unpublishProject(ID: $ID)
  }
`;

export const PublishPageQuery = gql`
  query PublishPageQuery($ID: CommonID!) {
    catalogProject(ID: $ID) {
      ...CatalogProjectFragment
    }
    project(ID: $ID) {
      ID
      versions {
        ...ProjectVersionFragment
      }
    }
  }

  ${CatalogProjectFragment}
  ${ProjectVersionFragment}
`;

export const ProjectReleaseFragment = gql`
  fragment ProjectReleaseFragment on ProjectRelease {
    ID
    projectID
    releaseTag
    releaseNotes
    releasedAt
    releasedBy {
      display
    }
  }
`;

export const GetProjectReleases = gql`
  query GetProjectReleases($projectID: CommonID!) {
    projectReleases(projectID: $projectID) {
      ...ProjectReleaseFragment
    }
  }

  ${ProjectReleaseFragment}
`;

export const CreateProjectRelease = gql`
  mutation CreateProjectRelease($input: CreateProjectReleaseInput!) {
    createProjectRelease(input: $input) {
      ...ProjectReleaseFragment
    }
  }

  ${ProjectReleaseFragment}
`;

export const UpgradeProject = gql`
  mutation UpgradeProject($ID: CommonID!) {
    upgradeProject(ID: $ID) {
      ...ProjectFragment
    }
  }

  ${ProjectFragment}
`;

export const ReleaseTagAvailable = gql`
  query ReleaseTagAvailable($projectID: CommonID!, $releaseTag: String!) {
    releaseTagAvailable(projectID: $projectID, releaseTag: $releaseTag)
  }
`;

export const DeployLibraryContent = gql`
  mutation DeployLibraryContent($input: DeployContentInput!) {
    deployContent(input: $input) {
      functions {
        snapID
        entityID
      }
    }
  }
`;
