import gql from 'graphql-tag';

export const CatalogProjectSummaryFragment = gql`
  fragment CatalogProjectSummaryFragment on CatalogProject {
    ID
    type
    owner {
      ID
      display
    }
    name
    tagLine
    description
    tags
    category
  }
`;

export const ProjectSnapshotFragment = gql`
  fragment ProjectSnapshotFragment on ProjectSnapshot {
    name
    description
    functions {
      ID
      name
      description
      inputs {
        ID
        label
        dataType
      }
      outputs {
        ID
        label
        dataType
      }
    }
  }
`;

export const CatalogProjectFragment = gql`
  fragment CatalogProjectFragment on CatalogProject {
    ID
    owner {
      display
    }
    type
    currentReleaseID
    name
    tagLine
    description
    publishedAt
    publishedBy {
      display
    }
    deployIntro
    urlSlug
    tags
    category
    googleTemplateURL
    releases {
      ID
      releasedAt
      releasedBy {
        display
      }
      releaseTag
      releaseNotes
    }
    deployments {
      ID
      upgradeAvailable {
        ID
      }
    }
    isLibrary
  }
`;

export const GetCatalogProject = gql`
  query GetCatalogProject($slugOrID: String) {
    catalogProject(slugOrID: $slugOrID) {
      ...CatalogProjectFragment
    }
  }

  ${CatalogProjectFragment}
`;

export const GetCatalogProjects = gql`
  query GetCatalogProjects {
    catalogProjects {
      ...CatalogProjectSummaryFragment
    }
  }

  ${CatalogProjectSummaryFragment}
`;

export const GetReleaseSnapshot = gql`
  query GetReleaseSnapshot($releaseID: CommonID!) {
    releaseSnapshot(releaseID: $releaseID) {
      ...ProjectSnapshotFragment
    }
  }
  ${ProjectSnapshotFragment}
`;

export const GetCatalogSnapshot = gql`
  query GetCatalogSnapshot($projectID: CommonID!) {
    catalogSnapshot(projectID: $projectID) {
      ...ProjectSnapshotFragment
    }
  }
  ${ProjectSnapshotFragment}
`;
