import React, { Fragment } from 'react';
import { TransitionGroup } from 'react-transition-group';
import orderBy from 'lodash/orderBy';
import styled from '@emotion/styled';

import FadeAndScaleUpTransition from '<src>/transitions/FadeAndScaleUp';

import { SectionHdr } from '<sections>/onboarding/styles';

import {
  ListContent,
  CardList,
  EmptyListContainer,
} from '<components>/NumbrzPageComponents';
import CatalogItem from './CatalogItem';

const List = styled(CardList)`
  grid-template-columns: repeat(auto-fill, 348px);
`;

function haveCommonItems(arr1, arr2) {
  const set1 = new Set(arr1);
  const commonItems = arr2.filter((item) => set1.has(item));
  return commonItems.length > 0;
}

function applySearchAndFilters(filters = {}, searchPattern, items = []) {
  let newItems = [...items];
  if (searchPattern) {
    newItems = orderBy(items, ['name'], ['asc']).filter(
      ({ name, tagLine, description, tags = [] }) => {
        let filter = false;
        if (
          searchPattern.test(name) ||
          searchPattern.test(tagLine) ||
          searchPattern.test(description)
        )
          filter = true;

        tags.forEach((t) => {
          if (searchPattern.test(t)) filter = true;
        });

        return filter;
      }
    );
  }

  if (filters.tags && filters.tags.length > 0) {
    newItems = newItems.filter(
      ({ tags, category }) =>
        haveCommonItems(filters.tags, tags) ||
        haveCommonItems(filters.tags, [category])
    );
  }
  if (filters.type && filters.type.length > 0) {
    if (filters.type.includes('service') && filters.type.includes('template'))
      return newItems;

    if (filters.type.includes('service'))
      newItems = newItems.filter(({ type }) => type === 'DeployService');
    if (filters.type.includes('template'))
      newItems = newItems.filter(({ type }) => type === 'DeploySource');
  }
  return newItems;
}

function InnerList({ projects, searchTerm, searchPattern, onDeploy }) {
  return (
    <List>
      <TransitionGroup component={Fragment}>
        {projects.map((project) => (
          <FadeAndScaleUpTransition key={project.ID} exitDuration={120}>
            <CatalogItem
              catalogProject={project}
              searchTerm={searchTerm}
              searchPattern={searchPattern}
              onDeploy={onDeploy}
            />
          </FadeAndScaleUpTransition>
        ))}
      </TransitionGroup>
    </List>
  );
}

export default function CatalogList({
  projects = [],
  searchTerm,
  searchPattern,
  filters,
  onDeploy,
}) {
  const filtered = applySearchAndFilters(filters, searchPattern, projects);
  const templates = filtered.filter((p) => p.type === 'DeploySource');
  const services = filtered.filter((p) => p.type === 'DeployService');

  if (filtered.length === 0) {
    return (
      <ListContent>
        <EmptyListContainer>
          <h4>No items found</h4>
        </EmptyListContainer>
      </ListContent>
    );
  }

  return (
    <ListContent>
      {templates.length > 0 ? (
        <>
          <SectionHdr>
            <span className="underline">Template</span> Models
          </SectionHdr>
          <p>Need a head start? Begin with a template and customize at will.</p>
          <InnerList
            projects={templates}
            searchTerm={searchTerm}
            searchPattern={searchPattern}
            onDeploy={onDeploy}
          />
        </>
      ) : null}
      {services.length > 0 ? (
        <>
          <SectionHdr>
            <span className="underline">Service</span> Models
          </SectionHdr>
          <p>
            Ready-to-use-models, complete with sample data and pre-configured
            jobs.
          </p>
          <InnerList
            projects={services}
            searchTerm={searchTerm}
            searchPattern={searchPattern}
            onDeploy={onDeploy}
          />
        </>
      ) : null}
    </ListContent>
  );
}
