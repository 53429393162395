import React, { useState, useRef } from 'react';

import {
  PopupDialog,
  SectionHdr,
  ModalFooter,
  ToggleBtn,
  Tag,
} from '../styles';
import TypeAheadSearch from '<components>/TypeAheadSearch';
import { List } from '<components>/TagList';
import { Icon } from 'semantic-ui-react';
import Button from '<components>/Button';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';
import FocusTrap from 'focus-trap-react';

export default function FilterDialog({
  visible,
  setVisible,
  filters: filtersProp,
  setFilters: setFiltersProp,
  tags,
}) {
  const [filters, setFilters] = useState(filtersProp);
  const menuEl = useRef();

  const trapOptions = {
    clickOutsideDeactivates: true,
    initialFocus: () => menuEl && menuEl.current,
    onDeactivate: () => setVisible(false),
  };

  const handleUpdateFilters = (filterType, value, action) => {
    if (filterType === 'tag') {
      if (action === 'add')
        setFilters({ ...filters, tags: [...filters.tags, value] });
      if (action === 'remove')
        setFilters({
          ...filters,
          tags: filters.tags.filter((t) => t !== value),
        });
    }
    if (filterType === 'type') {
      if (filters.type.includes(value))
        setFilters({
          ...filters,
          type: filters.type.filter((t) => t !== value),
        });
      if (!filters.type.includes(value))
        setFilters({
          ...filters,
          type: [...filters.type, value],
        });
    }
  };

  return !visible ? null : (
    <FocusTrap focusTrapOptions={trapOptions}>
      <PopupDialog
        visible={visible}
        tabIndex={0}
        width="450px"
        minHeight="350px"
        padding="20px;"
      >
        <SectionHdr>TAGS</SectionHdr>
        <TypeAheadSearch
          items={tags}
          onItemClick={(tag) => handleUpdateFilters('tag', tag, 'add')}
        />
        {filters.tags.length > 0 && (
          <List style={{ marginTop: 0 }}>
            {filters.tags.map((t, idx) => (
              <Tag key={idx} style={{ margin: '5px' }}>
                <span>{t}</span>
                <Icon
                  name="close"
                  onClick={() => handleUpdateFilters('tag', t, 'remove')}
                />
              </Tag>
            ))}
          </List>
        )}
        <SectionHdr>MODEL TYPE</SectionHdr>
        <FlexRowContainer alignItems="center" justifyContent="flex-start">
          <ToggleBtn
            selected={filters.type.includes('service')}
            onMouseDown={(e) => e.preventDefault()}
            onClick={(e) => {
              e.target.blur();
              handleUpdateFilters('type', 'service');
            }}
          >
            SERVICE
          </ToggleBtn>
          <ToggleBtn
            selected={filters.type.includes('template')}
            onMouseDown={(e) => e.preventDefault()}
            onClick={(e) => {
              e.preventDefault();
              handleUpdateFilters('type', 'template');
            }}
          >
            TEMPLATE
          </ToggleBtn>
        </FlexRowContainer>

        <ModalFooter>
          <Button
            onClick={() => {
              setFiltersProp(filters);
              setVisible(false);
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </PopupDialog>
    </FocusTrap>
  );
}
