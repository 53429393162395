import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { FormCheckbox } from 'semantic-ui-react';

import {
  EditableField,
  FlexRowContainer,
} from '<components>/NumbrzPageComponents';
import EditableMarkdown from '<components>/EditableMarkdown';
import TagList from '<components>/TagList';
import UnderlinedHeader from '<components>/UnderlinedHeader';
import Feature from '<components>/Feature';
import Button from '<components>/Button';

import { GetProject } from '../../queries';

import AddReleaseDialog from '../AddReleaseDialog';

const InlineEditor = styled(EditableField)`
  display: inline-block;
  width: initial;
  min-width: 140px;
`;

function CatalogURL({ urlSlug, onChange }) {
  const prefix = `${__DEV__ ? '' : window.location.origin}/catalog/`;
  return (
    <div>
      {prefix}
      <InlineEditor
        singleClick
        value={urlSlug}
        placeholder="No URL slug set"
        onSubmit={({ value }) => onChange(value)}
        underline
      />
    </div>
  );
}

function useCatalogAPI(updateProject, createRelease, project) {
  const { ID: projectID } = project;
  const catalogInfo = project.catalog;

  return useMemo(() => {
    const onUpdate = (prop) => (value) =>
      updateProject({
        variables: {
          input: { projectID, catalog: { [prop]: value } },
        },
      });
    const onUpdateTemplate = (prop) => (value) =>
      updateProject({
        variables: {
          input: { projectID, template: { [prop]: value } },
        },
      });

    const onAddItem = (prop) => (item) =>
      updateProject({
        variables: {
          input: {
            projectID,
            catalog: {
              [prop]: catalogInfo[prop].includes(item)
                ? catalogInfo[prop]
                : [...catalogInfo[prop], item].sort(),
            },
          },
        },
      });
    const onRemoveItem = (prop) => (item) =>
      updateProject({
        variables: {
          input: {
            projectID,
            catalog: {
              [prop]: !catalogInfo[prop].includes(item)
                ? catalogInfo[prop]
                : catalogInfo[prop].filter((t) => t !== item),
            },
          },
        },
      });

    const onCreateRelease = createRelease
      ? async (input) => {
          const resp = await createRelease({
            variables: { input: { ...input, projectID: project.ID } },
            update: (cache, { data: { createProjectRelease: newRelease } }) => {
              if (!newRelease) {
                return;
              }
              if (input.promote) {
                const variables = { ID: project.ID };
                const { project: cachedProject } = cache.readQuery({
                  query: GetProject,
                  variables,
                });
                cache.writeQuery({
                  query: GetProject,
                  variables,
                  data: {
                    project: {
                      ...cachedProject,
                      catalog: {
                        ...cachedProject.catalog,
                        currentReleaseID: newRelease.ID,
                        currentRelease: {
                          __typename: newRelease.__typename,
                          releaseTag: newRelease.releaseTag,
                          releasedAt: newRelease.releasedAt,
                        },
                      },
                    },
                  },
                });
              }
            },
          });
          return resp;
        }
      : null;

    const onSetPublish = onUpdate('publishToCatalog');
    const onSetIsLibrary = onUpdateTemplate('isLibrary');
    const onSetTagline = onUpdate('tagLine');
    const onSetDeployIntro = onUpdate('deployIntro');
    const onSetUrlSlug = onUpdate('urlSlug');
    const onAddTag = onAddItem('tags');
    const onRemoveTag = onRemoveItem('tags');
    const onSetCategory = onUpdate('category');

    return {
      onCreateRelease,
      onSetPublish,
      onSetIsLibrary,
      onSetTagline,
      onSetDeployIntro,
      onSetUrlSlug,
      onAddTag,
      onRemoveTag,
      onSetCategory,
    };
  }, [catalogInfo, createRelease, project.ID, projectID, updateProject]);
}

export default function CatalogSettings({
  project,
  releaseTagValid,
  updateProject,
  createRelease,
  onCheckReleaseTag,
}) {
  const [dialogVisible, setDialogVisible] = useState(false);

  const {
    onCreateRelease,
    onSetPublish,
    onSetIsLibrary,
    onSetTagline,
    onSetDeployIntro,
    onSetCategory,
    onSetUrlSlug,
    onAddTag,
    onRemoveTag,
  } = useCatalogAPI(updateProject, createRelease, project);

  const catalogInfo = project.catalog || { publishToCatalog: false };
  const templateInfo = project.template || { isLibrary: false };

  const publishTitle = catalogInfo.currentReleaseID
    ? 'Publish Updates'
    : 'Publish';

  return (
    <>
      <UnderlinedHeader>CATALOG</UnderlinedHeader>
      <FormCheckbox
        checked={catalogInfo.publishToCatalog}
        label="List this model in the public Catalog"
        onChange={(e, { checked }) => onSetPublish(checked)}
      />
      <FormCheckbox
        checked={templateInfo.isLibrary}
        label="List this model as a function library"
        onChange={(e, { checked }) => onSetIsLibrary(checked)}
      />

      {catalogInfo.publishToCatalog ? (
        <>
          <UnderlinedHeader>TAGLINE</UnderlinedHeader>
          {/* <Message info>
            Provide a brief description of your model. This appears in the
            catalog list view.
          </Message> */}
          <InlineEditor
            singleClick
            value={catalogInfo.tagLine}
            onSubmit={({ value }) => onSetTagline(value)}
            underline
            placeholder="Enter model tagline"
          />
          {project.type === 'Service' ? (
            <EditableMarkdown
              label="wizard introduction"
              content={catalogInfo.deployIntro}
              // message="This introduction is displayed when a user launches the
              //   deployment for this model. It should provide a brief description
              //   of what this model does, the data it uses, and so on"
              placeholder="No introduction provided."
              onSave={onSetDeployIntro}
            />
          ) : null}
          <Feature name="PublishModelSlug">
            <UnderlinedHeader>PUBLIC URL</UnderlinedHeader>
            {/* <Message info>
            Provide a public URL for this model to allow linking to its Catalog
            entry using a memorable, user-friendly URL.
            </Message> */}
            <CatalogURL urlSlug={catalogInfo.urlSlug} onChange={onSetUrlSlug} />
          </Feature>
          <UnderlinedHeader>CATEGORY</UnderlinedHeader>
          <InlineEditor
            singleClick
            value={catalogInfo.category}
            onSubmit={({ value }) => onSetCategory(value)}
            underline
            placeholder="Enter model category"
          />
          <UnderlinedHeader>TAGS</UnderlinedHeader>
          <TagList
            tags={catalogInfo.tags}
            addTag={onAddTag}
            removeTag={onRemoveTag}
          />
          {onCreateRelease ? (
            <FlexRowContainer>
              <Button
                action="create"
                onClick={() => setDialogVisible(true)}
                title={publishTitle}
              >
                {publishTitle}
              </Button>
              <AddReleaseDialog
                visible={dialogVisible}
                onClose={() => setDialogVisible(false)}
                onCreate={onCreateRelease}
                releaseTagValid={releaseTagValid}
                onCheckReleaseTag={onCheckReleaseTag}
              />
            </FlexRowContainer>
          ) : null}
        </>
      ) : null}
    </>
  );
}
